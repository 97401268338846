import { Box, Toolbar } from "@mui/material";
import React from "react";
import { classNames } from "../../utils/classNames";
import SidebarHeader from "./header.sidebar";
import { DRAWER_WIDTH } from "../../constants/layout.constants";
import SidebarDrawer from "./drawer.sidebar";

export default function SidebarLayout({ children, className, style }) {
  const [anchorElUser, setAnchorElUser] = React.useState(null);

  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  return (
    <Box
      sx={{ display: "flex" }}
      className={classNames(["sidebar-layout", className])}
    >
      {/* Header */}
      <SidebarHeader
        handleDrawerToggle={handleDrawerToggle}
        handleOpenUserMenu={handleOpenUserMenu}
        handleCloseUserMenu={handleCloseUserMenu}
        anchorElUser={anchorElUser}
      />
      <SidebarDrawer
        mobileOpen={mobileOpen}
        handleDrawerToggle={handleDrawerToggle}
      />
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          bgcolor: "background.paper",
          width: { sm: `calc(100% - ${DRAWER_WIDTH}px)` },
        }}
      >
        <Toolbar />

        <Box
          sx={{
            borderRadius: 1,
            bgcolor: "background.over",
            p: 3,
            minHeight: "calc(100vh - 104px)",
          }}
        >
          {children}
        </Box>
      </Box>
    </Box>
  );
}
