import React, { createContext, useEffect, useMemo, useState } from "react";

import axios from "axios";
import { API_URL } from "../constants/app.constants";
import { ToastContainer, toast } from "react-toastify";
const AppContext = createContext();

export const http = axios.create({
  baseURL: API_URL,
  timeout: 30000,
});

function AppProvider(props) {

  /**
   * Show a toastr
   * @param type (optional) 
   * @param message (required string) 
   * @return void
   */
  const showToast = ({ type, message }) => {
    const themeMode = window.localStorage["theme"]
      ? JSON.parse(window.localStorage["theme"])
      : "";

    const config = {
      position: "bottom-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: themeMode,
    };

    if (!type) {
      toast(message, config);
    }

    if (type === "error") {
      toast.error(message, config);
    }
    if (type === "info") {
      toast.info(message, config);
    }
    if (type === "success") {
      toast.success(message, config);
    }
    if (type === "warning") {
      toast.warn(message, config);
    }
  };
  const store = {
    showToast,
  };

  const storeForProvider = useMemo(() => store, [store]);
  return (
    <AppContext.Provider value={storeForProvider}>
      {props.children}
    </AppContext.Provider>
  );
}

export { AppContext };
export default AppProvider;
