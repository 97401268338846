import AppProvider from "./context/app.context";
import ThemeProvider from "./context/theme.context";
import UserProvider from "./context/user.context";
import MainNavigation from "./navigation/main.navigation";
import AdapterMoment from "@mui/lab/AdapterMoment";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CodesProvider from "./context/codes.context";

function App() {
  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <AppProvider>
        <ToastContainer />
        <ThemeProvider>
          <UserProvider>
            <CodesProvider>
              <MainNavigation />
            </CodesProvider>
          </UserProvider>
        </ThemeProvider>
      </AppProvider>
    </LocalizationProvider>
  );
}

export default App;
