import React from "react";
import DatePicker from "@mui/lab/DatePicker";
import { useFormik } from "formik";
import Modal from "../../components/modal/modal.component";
import moment from "moment";
import * as yup from "yup";
import { Grid, TextField } from "@mui/material";


export default function AreYouSureModal({
  code,
  modalVisible,
  setModalVisible,
  onSave,
}) {

  const closeModal = () => {
    setModalVisible(false);
  }
  return (
    <Modal
      saveButtonText = {'yes'}
      saveButtonColor = {'error'}
      cancelButtonText = {'no'}

      title={`Are you sure you want to delete this token?`}
      children = {`${code}`}
      
      visible={modalVisible}
      onClose={closeModal}

      onSave={() => {
        console.log("on save");
        onSave();
        closeModal();
      }}
    >
    </Modal>
  );
}
