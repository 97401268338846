import {
  AppBar,
  Avatar,
  Box,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  Toolbar,
  Tooltip,
  Typography,
  Grid,
} from "@mui/material";
import React, { useContext } from "react";
import { DRAWER_WIDTH } from "../../constants/layout.constants";
import { UserContext } from "../../context/user.context";
// Icons
import MenuIcon from "@mui/icons-material/Menu";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import DarkModeIcon from "@mui/icons-material/DarkMode";
import LightModeIcon from "@mui/icons-material/LightMode";

import { ThemeContext } from "../../context/theme.context";
import { getCurrentRouteTitle } from "../../utils/getRouteDetails";
import { useLocation, useNavigate } from "react-router-dom";

import {getRandomColor,createImageFromInitials} from '../../utils/utils.js'

export default function SidebarHeader({
  handleDrawerToggle,
  handleOpenUserMenu,
  handleCloseUserMenu,
  anchorElUser,
}) {
  const { mode, toggleTheme } = useContext(ThemeContext);
  const { user, signout } = useContext(UserContext);

  const location = useLocation();
  const navigate = useNavigate();
  return (
    <AppBar
      position="fixed"
      sx={{
        "background-image": "none",
        width: { sm: `calc(100% - ${DRAWER_WIDTH}px)` },
        ml: { sm: `${DRAWER_WIDTH}px` },
        bgcolor: "background.over",
        boxShadow: 0,
      }}
    >
      <Toolbar>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          edge="start"
          onClick={handleDrawerToggle}
          sx={{ mr: 2, display: { sm: "none" } }}
        >
          <MenuIcon />
        </IconButton>

        {/* Title */}
        {/* <Typography variant="h6" color="text.primary" noWrap component="div">
          {getCurrentRouteTitle()}
        </Typography> */}

        <Box sx={{ flexGrow: 1, display: "flex", justifyContent: "flex-end" }}>
          <Tooltip title="Open settings">
            <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
              <Avatar alt={user.userName} src={createImageFromInitials(500, user.userName, '#ffffff')} />
            </IconButton>
          </Tooltip>

          <Menu
            sx={{ mt: "45px" }}
            anchorEl={anchorElUser}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={Boolean(anchorElUser)}
            onClose={handleCloseUserMenu}
          >
            <Box sx={{ minWidth: 280 }}>

              <Grid container justify="space-between">  
  
              <Avatar sx={{ marginLeft: 1.5, marginTop:1, marginBottom: 0.5 }} src={createImageFromInitials(500, user.userName, '#ffffff')}></Avatar>
              <Typography  ml={2} marginTop={2}>
                  {user.userName}
              </Typography>
              </Grid>


              <Divider sx={{ marginTop: 1, marginBottom: 1 }} />
              
              <MenuItem
                onClick={() => {
                  toggleTheme();
                }}
              >
                {mode === "dark" ? <LightModeIcon /> : <DarkModeIcon />}
                <Typography ml={2} textAlign="center">
                  Change theme
                </Typography>
              </MenuItem>
              <MenuItem
                onClick={() => {
                  signout();
                }}
              >
                <ExitToAppIcon />
                <Typography ml={2} textAlign="center">
                  Logout
                </Typography>
              </MenuItem>
            </Box>
          </Menu>
        </Box>
      </Toolbar>
    </AppBar>
  );
}
