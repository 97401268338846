import React from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useTheme } from "@emotion/react";

export default function Modal({
  title,
  footer,
  children,
  onClose,
  onSave,
  visible,
  saveButtonText = "Save",
  saveButtonColor = "primary",
  cancelButtonText = "Cancel",
  
}) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Dialog  onClose={onClose} open={Boolean(visible)} fullScreen={fullScreen}>
      <DialogTitle>{title}</DialogTitle>

      <DialogContent sx={{minWidth:380}}>
        {children}
      </DialogContent>

      <DialogActions>
        {footer ? (
          footer
        ) : (
          <>
            <Button autoFocus onClick={onClose}>
              {cancelButtonText}
            </Button>
            <Button color={saveButtonColor} onClick={onSave}>{saveButtonText}</Button>
          </>
        )}
      </DialogActions>
    </Dialog>
  );
}
