import { Box } from "@mui/material";
import React from "react";
import { useContext } from "react";

import {
  BrowserRouter,
  Routes,
  Route,
  useLocation,
  Navigate,
} from "react-router-dom";
import DEPLOY_DIRECTORY from "../constants/app.constants";
import { AppContext } from "../context/app.context";
import { ThemeContext } from "../context/theme.context";
import { UserContext } from "../context/user.context";
import Page404Screen from "../screen/404/404.screen";
import { hasPermission } from "../utils/utils";
import { routes } from "./routes.navigation";

function RouteLogic({ route }) {
  const {  user } = useContext(UserContext);
  const { showToast } = useContext(AppContext);
  let location = useLocation();


  if (route.redirect) {
    return <Navigate to={route.redirect} state={{ from: location }} replace />;
  }
  if (!user && route.protected) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  if (user && route.redirectUser) {
    // Redirect user to
    return (
      <Navigate to={route.redirectUser} state={{ from: location }} replace />
    );
  }

  return route.element;
}

export default function MainNavigation() {
  const { toggleTheme } = useContext(ThemeContext);
  return (
    <BrowserRouter basename={DEPLOY_DIRECTORY}>
      <Routes>
        {routes.map((route) => {
          return (
            <Route
              key={route.path}
              path={route.path}
              element={<RouteLogic route={route}></RouteLogic>}
              exact={route.exact}
            />
          );
        })}

        {/* Using path="*"" means "match anything", so this route
                acts like a catch-all for URLs that we don't have explicit
                routes for. */}
        {/* 404 */}
        <Route path="*" element={<Page404Screen />} />
      </Routes>

    </BrowserRouter>
  );
}
