import { Box, Button, Typography } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import SidebarLayout from "../../layout/sidebar/sidebar.layout";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbar,
} from "@mui/x-data-grid";
import { IconButton, Chip } from "@mui/material";
import moment from "moment";
import EditIcon from "@mui/icons-material/Edit";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";

import AddEditUserModal from "./add-edit-user.modal";
import AreYouSureModal from "./are-you-sure-delete.modal";
import { DBContext } from "../../context/codes.context";

function CustomToolbar({ rightComponent }) {
  return (
    <GridToolbarContainer
      sx={{ marginBottom: 1, justifyContent: "space-between", padding: 0.5 }}
    >
      <Box>
        <GridToolbarDensitySelector  />
        <GridToolbarColumnsButton  />
        <GridToolbarFilterButton />
        <GridToolbarExport  printOptions={{ disableToolbarButton: true }} />
      </Box>

      {/* Test */}
      {rightComponent}
    </GridToolbarContainer>
  );
}

const getRowDetails = (params) => {
  const api = params.api;
  const row = {};

  api
    .getAllColumns()
    .filter((c) => c.field !== "__check__" && !!c)
    .forEach((c) => (row[c.field] = params.getValue(params.id, c.field)));

  return row;
};

export default function TokensScreen() {
  const [modalVisible, setModalVisible] = useState(false);
  const [modalVisibleDelete, setModalVisibleDelete] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [code, setCode] = useState(null);

  const { getAllCodesRequest } = useContext(DBContext);
  const { addTokenRequest } = useContext(DBContext);
  const { removeTokenRequest } = useContext(DBContext);

  const [data, setData] = useState([]);

  const getAndPopulateData = async () => {
    const result = await getAllCodesRequest();
    console.log(result.data);
    if (!result.error) {
      setData(result.data);
    }
  };

  const addToken = async (code, order_code, expire_at) => {
    const result = await addTokenRequest(code, order_code, expire_at);

    if (!result.error) {
      console.log(result.data);
    }

    getAndPopulateData();
  };

  const removeToken = async (code) => {
    const result = await removeTokenRequest(code);
    
    if (!result.error) {
      console.log(result.data);
    }

    getAndPopulateData();
  };

  useEffect(() => {
    getAndPopulateData();
  }, []);

  // Columns
  const columns = [
    { field: "id", type: "number", headerName: "ID", width: 100 },
    { field: "code", headerName: "Game code", width: 200 },
    { field: "order_code", headerName: "Order code", width: 200 },
    { field: "created_at",
      type: "date",
      headerName: "Created at",
      width: 200,
      valueGetter: (params) => {
        if (params.value) {
          return moment(params.value).format("DD/MM/YYYY");
        }
      },
    },
    { field: "expire_at",
      type: "date",
      headerName: "Expire at",
      width: 200,
      valueGetter: (params) => {
        if (params.value) {
          return moment(params.value).format("DD/MM/YYYY");
        }
      },
    },
    { field: "used",
      headerName: "Used",
      width: 100,
      renderCell: (params) => {
        return (
          <Chip
            label={params.value}
            color={params.value === "yes" ? "success" : "error"}
            variant="outlined"
          />
        );
      },
    },
    { field: "used_at", headerName: "Used at", width: 200 },
    { field: "action",
      headerName: "Delete Token",
      sortable: false,
      filterable: false,
      width: 120,
      renderCell: (params) => {
        return (
          <>
            <IconButton
              onClick={() => {
                let row = getRowDetails(params);
                console.log(row['code']);
                setCode(row['code']);
                setModalVisibleDelete(true);
                //remove token via api with code

              }}
            >
              <DeleteOutlineIcon sx={{ color: "error.main" }} />
            </IconButton>
          </>
        );
      },
    },
  ];

  return (
    <SidebarLayout clasName="dashboard">
      <Typography
        sx={{ mb: -1, color: "text.primary", fontWeight: "700" }}
        variant="h5"
      >
        Tokens
      </Typography>
      <Typography
        sx={{ mb: 3, color: "text.secondary", fontWeight: "300" }}
        variant="subtitle1"
      >
        Manage all your tokens
      </Typography>

      <AreYouSureModal
        code = {code}
        modalVisible={modalVisibleDelete}
        setModalVisible={setModalVisibleDelete}

        onSave={()  => {
          console.log('deleted: ' + code);
          {removeToken(code)}
          setModalVisibleDelete(false);
        }}
      
      />

      <AddEditUserModal
        key={selectedUser}
        selectedUser={selectedUser}
        setSelectedUser={setSelectedUser}
        modalVisible={modalVisible}
        setModalVisible={setModalVisible}
        onSave={(formData)  => {
          setModalVisible(false);
          console.log("Form Data: ", formData);
          if (selectedUser) {
            // Update
            const futureData = data.map((e) => {
              if (e.id === selectedUser.id) {
                e.code = formData.code;
                e.order_code = formData.order_code;
                e.age = 2022 - moment(formData.expire_at).format("yyyy");
              }

              return e;
            });

            setData(futureData.data);
            setModalVisible(false);
            return;
          }

          // Create new Token
          addToken(formData.code, formData.order_code, moment(formData.expire_at).format('YYYY-MM-DD'));
          
          setData([
            ...data,
            {
              id: data[data.length - 1].id + 1,
              code: formData.code,
              order_code: formData.order_code,
              age: formData.expire_at,
            },
          ]);

          setModalVisible(false);
        }}
      />

      <div style={{ height: "calc(100vh - 250px)", width: "100%" }}>
        <DataGrid

          initialState={{
            sorting: {
              sortModel: [{ field: 'created_at', sort: 'desc' }],
            },
          }}

          isRowSelectable={() => false}
          responsive="scroll"
          rows={data.gameCodes}
          columns={columns}
          pageSize={100}
          rowsPerPageOptions={[100]}

          components={{

            Toolbar: () => (
              <CustomToolbar
                rightComponent={
                  <div>
                    <Button
                      variant="contained"
                      onClick={() => {
                        setModalVisible(true);
                      }}
                    >
                      Create Token
                    </Button>
                  </div>
                }
              />
            ),
          }}
        />
      </div>
    </SidebarLayout>
  );
}
