import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import useLocalStorage from "../hooks/useLocalStorage.hook";
import { AppContext, http } from "./app.context";

const UserContext = createContext();

function UserProvider(props) {
  const [user, setUser] = useLocalStorage("user", null);
  const [token, setToken] = useLocalStorage("token", null);
  const { showToast } = useContext(AppContext);

  // Set token
  useEffect(() => {
    if (token) {
      http.defaults.headers.common['X-Authorization'] = token;
    }
  }, [token]);

  /**
   * Login users
   */
  const loginRequest = async ({ userName, password }) => {

    const result = await http.get(`/verifyInput/${userName}/${password}`);

    if(result.data.error){
      showToast({ message: "Wrong credentials!", type: "error" });
      return;
    }

    setToken(result.data.user.auth_token);
    delete result.data.user.auth_token;
    console.log({userName});
    setUser({userName});
    showToast({ message: `Welcome back, ${userName}!`, type: "success" });
  };


  const errorHandler = ({ error }) => {
    // Todo move to sentry

    if (error.response.status === 401) {
      signout();
    }
  };

  useEffect(() => {
    //Request interceptor
    http.interceptors.response.use(function (response) {
      // Do something with response data
      return response;
    });
  }, []);

  const signout = () => {
    // Clear token
    http.defaults.headers.common['X-Authorization'] = "";
    setToken(null);
    showToast({ message: "You have been signed out" });
    setUser(null);
  };
  
  const store = {
    user,
    setUser,
    loginRequest,
    signout,
    errorHandler
  };

  const storeForProvider = useMemo(() => store, [store]);
  return (
    <UserContext.Provider value={storeForProvider}>
      {props.children}
    </UserContext.Provider>
  );
}

export { UserContext };
export default UserProvider;
