import { Box, Button, Typography } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import SidebarLayout from "../../layout/sidebar/sidebar.layout";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbar,
} from "@mui/x-data-grid";
import { IconButton, Chip } from "@mui/material";
import moment from "moment";
import EditIcon from "@mui/icons-material/Edit";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";

import AddEditUserModal from "./add-edit-user.modal";
import { DBContext } from "../../context/codes.context";
import { isEndOfRange } from "@mui/lab/internal/pickers/date-utils";

function CustomToolbar({ rightComponent }) {
  return (
    <GridToolbarContainer
      sx={{ marginBottom: 2, justifyContent: "space-between", padding: 2 }}
    >
      <Box>
        <GridToolbarDensitySelector sx={{ marginLeft: 1 }} />
        <GridToolbarColumnsButton sx={{ marginLeft: 1 }} />
        <GridToolbarFilterButton sx={{ marginLeft: 1 }} />
        <GridToolbarExport sx={{ marginLeft: 1 }} />
      </Box>

      {/* Test */}
      {rightComponent}
    </GridToolbarContainer>
  );

}

function secondsToHms(d) {
  d = Number(d);
  var h = Math.floor(d / 3600);
  var m = Math.floor(d % 3600 / 60);
  var s = Math.floor(d % 3600 % 60);

  var hDisplay = h > 0 ? h + (h == 1 ? "h " : "h ") : "";
  var mDisplay = m > 0 ? m + (m == 1 ? "m " : "m ") : "";
  var sDisplay = s > 0 ? s + (s == 1 ? "s" : "s") : "";
  
  if(h>0){
    return hDisplay + mDisplay;
  }

  if(mDisplay+sDisplay===''){
    return '-';
  }
  return mDisplay + sDisplay; 
}


function getNumberOnly(str){

  if(str===null || str==='null'){
    return '';
  }
  var numb = str.match(/\d/g);
  numb = numb.join("");

  if(numb==='4'){
    return 'solution';
  }
  return numb;
}




const getRowDetails = (params) => {
  const api = params.api;
  const row = {};

  api
    .getAllColumns()
    .filter((c) => c.field !== "__check__" && !!c)
    .forEach((c) => (row[c.field] = params.getValue(params.id, c.field)));

  return row;
};

export default function TokensScreen() {
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);

  const { getAllPlayersRequest } = useContext(DBContext);

  const [data, setData] = useState([]);

  const getAndPopulateData = async () => {
    const result = await getAllPlayersRequest();
    console.log(result.data);
    if (!result.error) {
      setData(result.data);
    }
  };

  useEffect(() => {
    getAndPopulateData();
  }, []);


  // Columns
  const columns = [
    { field: "code", headerName: "Game code", width: 200 },
    { field: "order_code", headerName: "Order code", width: 200 },
    { field: "created_at",
      type: "date",
      headerName: "Started at",
      width: 200,
    },
    { field: "total_time",
      headerName: "Total Time",
      type: "integer",
      width: 125,
      renderCell: (params) => {
        return (
          <Chip
            label={params.value > 0 ? secondsToHms(params.value) : 'not started'}
            color={params.value > 0 ? "success" : "error"}
            variant="outlined"
          />
        );
      },
    },
  ];

  //create the riddle time columns
  for(let i = 5;i<=18;i++){
    columns[i] = { field: "time_riddle" + (i-4),type: "integer",headerName: "Time R" + (i-4), width: 90,
      renderCell: (params) => {
        return (
          secondsToHms(params.value) // transform the seconds in time format
        );
      },
    }
  };

  //create the hints used columns
  for(let i = 19;i<=32;i++){
    columns[i] = { field: "hints_riddle" + (i-18),headerName: "Hints R" + (i-18), width: 90,
  
      renderCell: (params) => {
        return (
          params.value > '0' ? getNumberOnly(params.value) : '' // get just the number of how many hints were used from SQL
        );
      },
  
    };
  };

  columns[33] = { field: "hints_riddle142",headerName: "Hints R14-2", width: 110,
  
      renderCell: (params) => {
        return (
          params.value > '0' ? getNumberOnly(params.value) : '' // get just the number of how many hints were used from SQL
        );
      },
  
    };


  columns[34] = { field: "fun", headerName: "Fun", width: 80 ,
  
    renderCell: (params) => {
      return (
        params.value > 0 ? params.value : '-' 
      );
    },

  };

  columns[35] = { field: "difficulty", headerName: "Difficulty", width: 90 ,
  
    renderCell: (params) => {
      return (
        params.value > 0 ? params.value : '-' 
      );
    },

  };

  columns[36] = { field: "recommend", headerName: "Recommend", width: 90 ,
  
    renderCell: (params) => {
      return (
        params.value > 0 ? (params.value == 1 ? 'yes' : params.value == 2 ? 'maybe': 'no') : '-' 
      );
    },

  };


  return (
    <SidebarLayout clasName="dashboard">
      <Typography
        sx={{ mb: -1, color: "text.primary", fontWeight: "700" }}
        variant="h5"
      >
        Statistics
      </Typography>
      <Typography
        sx={{ mb: 3, color: "text.secondary", fontWeight: "300" }}
        variant="subtitle1"
      >
      </Typography>


      <div style={{ height: "calc(100vh - 250px)", width: "100%" }}>
        <DataGrid
          initialState={{
            sorting: {
              sortModel: [{ field: 'created_at', sort: 'desc' }],
            },
          }}
          

          isRowSelectable={() => false}
          responsive="scroll"
          rows={data.gameCodes}
          columns={columns}
          pageSize={100}
          rowsPerPageOptions={[100]}
          csvOptions={{ disable: true }}
          components={{ Toolbar: GridToolbar }}

          componentsProps={{ toolbar: { printOptions: { disableToolbarButton: 'true' }}}}
        />
      </div>
    </SidebarLayout>
  );
}
