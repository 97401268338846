import {
  Box,
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Toolbar,
} from "@mui/material";
import React, { useContext } from "react";
import { DRAWER_WIDTH } from "../../constants/layout.constants";
import { UserContext } from "../../context/user.context";
import {FaChartBar  } from 'react-icons/fa';

// Icons
import GroupIcon from "@mui/icons-material/Group";
import DashboardIcon from "@mui/icons-material/Dashboard";
import { useLocation, useNavigate } from "react-router-dom";
import { hasPermission } from "../../utils/utils";
import { Balance, BalanceRounded } from "@mui/icons-material";
import { ThemeContext } from "../../context/theme.context";

export default function SidebarDrawer({ mobileOpen, handleDrawerToggle }) {
  const { user, signout } = useContext(UserContext);
  const { mode, toggleTheme } = useContext(ThemeContext);

  const location = useLocation();
  let navigate = useNavigate();

  const drawer = (
    <div>
      <Toolbar>
        <img
          style={{ width: 200 }}
          src={mode!='dark' ? process.env.PUBLIC_URL + "/logoBlack.png" : 'https://www.adventure-team.eu/wp-content/uploads/2021/06/Logo-Adventure-Team-inverted.png'}
        />
      </Toolbar>
      <Divider />
      <List>
        {/* Users list */}
        <ListItem
          onClick={() => {
            navigate("/dashboard");
          }}
          selected={location.pathname.includes("/dashboard")}
          button
        >
          <ListItemIcon>
            <DashboardIcon />
          </ListItemIcon>
          <ListItemText primary={"Dashboard"} />
        </ListItem>

        <ListItem
          button
          onClick={() => {
            navigate("/tokens");
          }}
          selected={location.pathname.includes("/tokens")}
        >
          <ListItemIcon>
            <GroupIcon />
          </ListItemIcon>
          <ListItemText primary={"Tokens"} />
        </ListItem>

        <ListItem
          button
          onClick={() => {
            navigate("/players");
          }}
          selected={location.pathname.includes("/players")}
        >
          <ListItemIcon>
          <FaChartBar />
          </ListItemIcon>
          <ListItemText primary={"Statistics"} />
        </ListItem>
      </List>
    </div>
  );

  return (
    <Box
      component="nav"
      sx={{ width: { sm: DRAWER_WIDTH }, flexShrink: { sm: 0 } }}
      aria-label="mailbox folders"
    >
      {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
      <Drawer
        variant="temporary"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        sx={{
          display: { xs: "block", sm: "none" },
          "& .MuiDrawer-paper": {
            bgcolor: "background.over",
            boxSizing: "border-box",
            width: DRAWER_WIDTH,
          },
        }}
      >
        {drawer}
      </Drawer>
      <Drawer
        variant="permanent"
        sx={{
          display: { xs: "none", sm: "block" },
          "& .MuiDrawer-paper": {
            bgcolor: "background.over",
            boxSizing: "border-box",
            width: DRAWER_WIDTH,
          },
        }}
        open
      >
        {drawer}
      </Drawer>
    </Box>
  );
}
