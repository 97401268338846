import React, { useContext, useState } from "react";
import FullLayout from "../../layout/full/full.layout";
import Button from "@mui/material/Button";
import { Box, TextField } from "@mui/material";
import Typography from "@mui/material/Typography";
import { UserContext } from "../../context/user.context";
import * as yup from "yup";
import { useFormik } from "formik";
import LoadingButton from "@mui/lab/LoadingButton";
import { AppContext } from "../../context/app.context";
import FancyTitle from "../../components/fancy-title/fancy-title.component";
import useLocalStorage from "../../hooks/useLocalStorage.hook";

const validationSchema = yup.object({
  userName: yup.string().required("User name is required"),
  password: yup
    .string()
    .required("No password provided.")
});

export default function LoginScreen() {
  const { loginRequest } = useContext(UserContext);
  const [number,setNumber] = useLocalStorage("number", 0);
  const [isLoading, setIsLoading] = useState(false);

  const { showToast } = useContext(AppContext);
  const formik = useFormik({
    initialValues: {
      userName: "",
      password: "",
    },
    validationSchema,
    onSubmit: async (values) => {
      setIsLoading(true);
      await loginRequest(values);
      setIsLoading(false);
    },
  });


  return (
    <FullLayout className="login-screen">
      <Box
        className="container"
        sx={{
          borderRadius: 1,
          boxShadow: 24,
          bgcolor: "background.over",
          minWidth: "350px",
          maxWidth: "380px",
          padding: "64px 32px",
        }}
      >
        <Typography
          sx={{ fontWeight: "fontWeightBold" }}
          color="text.primary"
          variant="h5"
        >
          Login
        </Typography>


        <Typography mb={2} color="text.secondary" variant="subtitle2">
          Admin Dashboard
        </Typography>

        <TextField
          size="small"
          autoComplete="off"
          variant="outlined"
          label="User name"
          color="primary"
          placeholder="User name"
          sx={{ marginTop: 3, width: "100%" }}
          // Formik data
          name="userName"
          value={formik.values.userName}
          onChange={formik.handleChange}
          error={formik.touched.userName && Boolean(formik.errors.userName)}
          helperText={formik.touched.userName && formik.errors.userName}
        />
        <TextField
          size="small"
          autoComplete="off"
          variant="outlined"
          type="password"
          label="Password"
          color="primary"
          sx={{ marginTop: 3, width: "100%" }}
          // Formik data
          name="password"
          value={formik.values.password}
          onChange={formik.handleChange}
          error={formik.touched.password && Boolean(formik.errors.password)}
          helperText={formik.touched.password && formik.errors.password}
        />

        <Box sx={{ marginTop: 4, display: "flex", justifyContent: "flex-end" }}>
          <LoadingButton
            loading={isLoading}
            onClick={() => {
              formik.handleSubmit();
            }}
            variant="contained"
          >
            Login
          </LoadingButton>
        </Box>
      </Box>
    </FullLayout>
  );
}
