import React, { createContext, useEffect, useContext, useMemo, useState } from "react";
import { AppContext, http } from "./app.context";

const DBContext = createContext();

function CodesProvider(props) {

  const { showToast } = useContext(AppContext);

  const getAllCodesRequest = async () => {

    const result = await http.get("/getTokens");
    
    if(result.data.error){

      showToast({ message: "Can't load data", type: "error" });
      return {error:true, data: []}
    }
    
    return {error:false, data: result.data }
  }
  const getAllStatsRequest = async () => {

    const result = await http.get("/getAndCreateDasboardData");
    
    if(result.data.error){

      showToast({ message: "Can't load data", type: "error" });
      return {error:true, data: []}
    }
    
    return {error:false, data: result.data }
  }

  const getAllPlayersRequest = async () => {

    const result = await http.get("/getPlayers");
    
    if(result.data.error){

      showToast({ message: "Can't load data", type: "error" });
      return {error:true, data: []}
    }
    
    return {error:false, data: result.data }
  }

  const addTokenRequest = async (code, order_code, expire_at) => {

    const result = await http.get(`/addToken/${code}/${order_code}/${expire_at}`);
    
    if(result.data.error){

      showToast({ message: "Can't load data", type: "error" });
      return {error:true, data: []}
    }
    
    return {error:false, data: result.data }
  }

  const removeTokenRequest = async (code) => {

    const result = await http.get(`/removeToken/${code}`);
    
    if(result.data.error){

      showToast({ message: "Can't load data", type: "error" });
      return {error:true, data: []}
    }
    
    return {error:false, data: result.data }
  }

  const getRatingRequest = async () => {

    const result = await http.get("/getRating");
    
    if(result.data.error){

      showToast({ message: "Can't load data", type: "error" });
      return {error:true, data: []}
    }
    
    return {error:false, data: result.data }
  }


  const store = {
    getAllCodesRequest,
    getAllPlayersRequest,
    addTokenRequest,
    removeTokenRequest,
    getAllStatsRequest,
    getRatingRequest,
  };

  const storeForProvider = useMemo(() => store, [store]);
  return (
    <DBContext.Provider value={storeForProvider}>
      {props.children}
    </DBContext.Provider>
  );
}

export { DBContext };
export default CodesProvider;
