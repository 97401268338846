import { Grid, Typography, useTheme } from "@mui/material";
import SidebarLayout from "../../layout/sidebar/sidebar.layout";
import { ResponsiveBar } from "@nivo/bar";
import { ResponsiveLine } from "@nivo/line";
import { ResponsivePie } from "@nivo/pie";
import { ResponsiveCalendar } from "@nivo/calendar";
import { ResponsiveRadar } from "@nivo/radar";
import { ThemeContext } from "../../context/theme.context";

import { randomInt } from "../../utils/utils";


import React, { useContext, useEffect, useState } from "react";

import { DBContext } from "../../context/codes.context";
import { width } from "@mui/system";



function secondsToHms(d) {
  d = Number(d);
  var h = Math.floor(d / 3600);
  var m = Math.floor(d % 3600 / 60);
  var s = Math.floor(d % 3600 % 60);

  var hDisplay = h > 0 ? h + (h == 1 ? "h " : "h ") : "";
  var mDisplay = m > 0 ? m + (m == 1 ? "m " : "m ") : "";
  var sDisplay = s > 0 ? s + (s == 1 ? "s" : "s") : "";

  
  
  if(h>0){
    return hDisplay + mDisplay;
  }
  return mDisplay + sDisplay; 
}






export default function DashboardScreen() {
  const { mode, toggleTheme } = useContext(ThemeContext);
  const { getAllStatsRequest } = useContext(DBContext);
  const { getRatingRequest } = useContext(DBContext);

  const [data, setData] = useState([]);
  const [dataRating, setDataRating] = useState([]);

  var backgroundColor = (mode === "dark" ? '#262626' : '#a5a5a5')
  var textColor = (mode === "dark" ? 'white' : 'black')
  const themeRating = {
    axis: {
      fontSize: "20px",
      tickColor: textColor,
      ticks: {
        line: {
          stroke: textColor
        },
        text: {
          fill: textColor
        }
      },
      legend: {
        text: {
          fill: textColor
        }
      }
    },
    grid: {
      line: {
        stroke: backgroundColor
      }
    }
  };

  const getAndPopulateData = async () => {
    const result = await getAllStatsRequest();
    console.log(result.data);
    if (!result.error) {
      setData(result.data);
    }
  };

  const getAndPopulateDataRating = async () => {
    const result = await getRatingRequest();
    console.log(result.data);
    if (!result.error) {
      setDataRating(result.data);
    }
  };

  useEffect(() => {
    getAndPopulateData();
    getAndPopulateDataRating();
  }, []);

  const ratingData = [
    {
      id: "Fun",
      data: [
        { x: "Fun", y: 1 },
        { x: "Fun", y: Math.round(dataRating['funGrade'] * 10) / 10  }
      ]
    },
    {
      id: "Difficulty",
      data: [
        { x: "Difficulty", y: 1 },
        { x: "Difficulty", y: Math.round(dataRating['difficultyGrade'] * 10) / 10  }
      ]
    },
    {
      id: "Recommend",
      data: [
        { x: "Recommend", y: 1 },
        { x: "Recommend", y: Math.round(dataRating['recomendGrade'] * 10) / 10  }
      ]
    }
  ];


  return (
    <SidebarLayout clasName="dashboard">
      <Typography sx={{ mb: -1, color: "text.primary", fontWeight: "700",}} variant="h5">
        Dashboard
      </Typography>
      <Typography sx={{ mb: 3, color: "text.secondary", fontWeight: "300",}} variant="subtitle1">
        View all your stats
      </Typography>

      <Grid
        container
        spacing={0}
        gap={3}
        sx={{
          justifyContent: "space-between",
        }}
      >
          

        <Grid
          sx={{
            height: 350,
            bgcolor: "background.subtil",
            padding: 3,
          }}
          item
          xs={12}
          xl={5.8}
          sm={5.8}
        >
          <Typography sx={{ mb: 3, color: "darkgreen", fontSize:'24px' }} variant="subtitle2">
            Last 28 days
          </Typography>

          <Typography sx={{ mb: 3, color: "text.primary" }} variant="subtitle2">
            {"- Tickets sold: " + data['lastTokens']}
          </Typography>

          <Typography sx={{ mb: 3, color: "text.primary" }} variant="subtitle2">
            {"- Groups created: " + data['lastOrders']}
          </Typography>

          <Typography sx={{ mb: 3, color: "text.primary" }} variant="subtitle2">
            {"- Played: " + data['lastPlayers']}
          </Typography>

          <Typography sx={{ mb: 3, color: "text.primary" }} variant="subtitle2">
            {"- Groups played: " + data['lastPlayedGroups']}
          </Typography>

          <Typography sx={{ mb: 3, color: "text.primary" }} variant="subtitle2">
            {"- Fastest completion: " + secondsToHms(data['minTime28'])}
          </Typography>

          <Typography sx={{ mb: 3, color: "text.primary" }} variant="subtitle2">
            {"- Slowest completion: " + secondsToHms(data['maxTime28'])}
          </Typography>



        </Grid>

        <Grid
          sx={{
            height: 350,
            bgcolor: "background.subtil",
            padding: 3,
          }}
          item
          xs={12}
          xl={5.8}
          sm={5.8}
        >
          <Typography sx={{ mb: 3, color: "darkred", fontSize:'24px' }} variant="subtitle2">
            All time
          </Typography>

          <Typography sx={{ mb: 3, color: "text.primary" }} variant="subtitle2">
            {"- Tickets sold: " + data['tokensNumber']}
          </Typography>

          <Typography sx={{ mb: 3, color: "text.primary" }} variant="subtitle2">
          {"- Groups created: " + data['ordersNumber']}
          </Typography>

          <Typography sx={{ mb: 3, color: "text.primary" }} variant="subtitle2">
          {"- Played: " + data['playersNumber']}
          </Typography>

          <Typography sx={{ mb: 3, color: "text.primary" }} variant="subtitle2">
            {"- Groups played: " + data['groupsPlayedNumber']}
          </Typography>

          <Typography sx={{ mb: 3, color: "text.primary" }} variant="subtitle2">
            {"- Fastest completion: " + secondsToHms(data['minTimeAllTime'])}
          </Typography>

          <Typography sx={{ mb: 3, color: "text.primary" }} variant="subtitle2">
            {"- Slowest completion: " + secondsToHms(data['maxTimeAllTime'])}
          </Typography>

        </Grid>
          
        <Grid
          sx={{
            height: 350,
            bgcolor: "background.subtil",
            padding: 2,
          }}
          item
          xs={12}
          xl={5.8}
          sm={5.8}
        >
          <Typography sx={{ mb: 3, color: "#ffa902", fontSize:'24px' }} variant="subtitle2">
            Rating
          </Typography>

          
          <ResponsiveLine
            data={ratingData}
            
            margin={{ top: 25, right: 110, bottom: 100, left: 50 }}
            lineWidth={15}

            height={270}
            width={350}

            yScale={{
              type: "linear",
              min: "1",
              max: "5",
              stacked: false,
              reverse: false
            }}
            axisTop={null}
            axisRight={null}
            axisLeft={{
              orient: "left",
              tickSize: 5,
              tickPadding: 10,
              tickRotation: 0,
              legend: "Grade",
              legendOffset: -40,
              legendPosition: "middle"
            }}
            axisBottom={{
              //tickValues: "every 2 days",
              // tickRotation: -90,
              legendOffset: -12
            }}

            
            theme={themeRating}
            colors={[ 'orange', '#2796d6' , '#9829d9']}
            pointSize={1}
            pointColor={{ theme: "background" }}
            pointBorderWidth={2}
            pointBorderColor={{ from: "serieColor" }}
            pointLabelYOffset={-12}
            useMesh={true}
            legends={[
              {
                anchor: "bottom-right",
                direction: "column",
                justify: false,
                translateX: 100,
                translateY: 0,
                itemsSpacing: 0,
                itemDirection: "left-to-right",
                itemWidth: 80,
                itemHeight: 20,
                itemOpacity: 1,
                itemTextColor: textColor,
                symbolSize: 12,
                symbolShape: "circle",
                symbolBorderColor: "rgba(0, 0, 0, 1)",
                effects: [
                  {
                    on: "hover",
                    style: {
                      itemBackground: "gray",
                      itemOpacity: 1
                    }
                  }
                ]
              }
            ]}
          />
        </Grid>

      <Grid
          sx={{
            height: 350,
            bgcolor: "background.subtil",
            padding: 3,
          }}
          item
          xs={12}
          xl={5.8}
          sm={5.8}
        >
         
        <Typography sx={{mb: 3, color: "#89CFF0", fontSize:'24px' }} variant="subtitle2">
              {"Do you recommend this game?"}
        </Typography>

        <Typography sx={{ mb: 3, color: "text.primary" }} variant="subtitle2">
        {"- Yes: " + dataRating['yesK'] + ' / ' + dataRating['playersNumber'] }
        </Typography>

        <Typography sx={{ mb: 3, color: "text.primary" }} variant="subtitle2">
        {"- Maybe: " + dataRating['maybeK'] + ' / ' + dataRating['playersNumber'] }
        </Typography>

        <Typography sx={{ mb: 3, color: "text.primary" }} variant="subtitle2">
        {"- No: " + dataRating['noK'] + ' / ' + dataRating['playersNumber'] }
        </Typography>

        <Typography sx={{ mb: 3, color: "#a5a5a5" }} variant="subtitle2">
        {"- No Vote: " + dataRating['noVote'] + ' / ' + dataRating['playersNumber'] }
        </Typography>

      </Grid>
        
    </Grid>
    </SidebarLayout>
  );
}
