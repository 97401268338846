import React from "react";
import DatePicker from "@mui/lab/DatePicker";
import { useFormik } from "formik";
import Modal from "../../components/modal/modal.component";
import moment from "moment";
import * as yup from "yup";
import { Grid, TextField } from "@mui/material";

const validationSchema = yup.object({
  code: yup
    .string("Enter Code")
    .required("Code is required"),
  order_code: yup
    .string("Enter the Order Code")
    .required("Order Code is required"),
  expire_at: yup
    .string("Enter the Order Code")
    .required("Order Code is required"),
});

export default function AddEditUserModal({
  selectedUser,
  setSelectedUser,
  modalVisible,
  setModalVisible,
  onSave,
}) {
  const formik = useFormik({
    initialValues: {
      code: selectedUser?.code || "",
      order_code: selectedUser?.order_code || "",
      expire_at: moment().add(21, "days"),
    },
    validationSchema,
    onSubmit: (values) => {
      onSave(values);
      closeModal();
    },
  });

  const closeModal = () => {
    setModalVisible(false);
  }
  return (
    <Modal
      title={selectedUser ? `Edit user ${selectedUser.id}` : "Add user"}
      visible={modalVisible}
      onClose={closeModal}
      onSave={() => {
        console.log("on save");
        formik.handleSubmit();
      }}
    >
      <Grid container sx={{ marginTop: "0px" }} spacing={2}>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Code"
            fullWidth
            // Formik data
            name="code"
            value={formik.values.code}
            onChange={formik.handleChange}
            error={formik.touched.code && Boolean(formik.errors.code)}
            helperText={formik.touched.code && formik.errors.code}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Order Code"
            fullWidth
            // Formik data
            name="order_code"
            value={formik.values.order_code || ""}
            onChange={formik.handleChange}
            error={formik.touched.order_code && Boolean(formik.errors.order_code)}
            helperText={formik.touched.order_code && formik.errors.order_code}
          />
        </Grid>

        <Grid item xs={12} sm={12}>
          <DatePicker
            inputFormat="DD/MM/yyyy"
            minDate={moment()}
            openTo="year"
            views={["year", "month", "day"]}
            label={"Expire date"}
            renderInput={(params) => (
              <TextField sx={{ marginTop: 2 }} fullWidth {...params} />
            )}
            // Formik data
            name="expire_at"
            value={formik.values.expire_at || ''}
            onChange={(e) => {
              // Look at this
              formik.setFieldValue("expire_at", e);
            }}
            error={formik.touched.expire_at && Boolean(formik.errors.expire_at)}
            helperText={formik.touched.expire_at && formik.errors.expire_at}
          />
        </Grid>
      </Grid>
    </Modal>
  );
}
