import DashboardScreen from "../screen/dashboard/dashboard.screen";
import LoginScreen from "../screen/login/login.screen";
import ProfileScreen from "../screen/profile/profile.screen";
import UsersScreen from "../screen/users/users.screen";
import PlayersScreen from "../screen/users/players.screen";

// If protected you can't enter without user
// If redirectUser, you can't enter with user
export const routes = [
  {
    path: "/",
    redirect: "/dashboard",
    exact: true,
    element: <DashboardScreen />,
    protected: true,
  },
  {
    path: "/login",
    title: "Login",
    exact: true,
    element: <LoginScreen />,
    protected: false,
    redirectUser: "/",
  },
  {
    title: "Dashboard",
    path: "/dashboard",
    exact: true,
    element: <DashboardScreen />,
    protected: true,
  },
  {
    path: "/tokens",
    exact: true,
    title: "Tokens",
    element: <UsersScreen />,
    protected: true,
  },
  {
    path: "/players",
    exact: true,
    title: "Players",
    element: <PlayersScreen />,
    protected: true,
  },
  {
    path: "/profile",
    exact: true,
    title: "My profile",
    element: <ProfileScreen />,
    protected: true,
  },
  //   { path: "/about", exact: false, element: LoginScreen, protected: true },
];
